import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`about`}</h2>
    <blockquote>
      <p parentName="blockquote">{`The potential for Ethereum to improve the lives of 1.4 billion African people is immense`}</p>
    </blockquote>
    <p>{`Africa is rife with challenges: some countries experience prolonged conflict, others economic hardship, or problems with food distribution and corruption.`}</p>
    <p>{`Equally there are tremendous opportunities in Africa with a very young and tech savvy population (median age of 19) who are willing to tackle the continent's issues.`}</p>
    <p>{`Ethereum can serve as the platform to host innovative solutions to improve the lives of more than a billion people.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      