/** @jsx jsx */
import { jsx } from "theme-ui"
import React from 'react'
import {slide as Menu} from 'react-burger-menu'

class Hbmenu extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        menuOpen: false
      }
    }
  
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})  
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
      this.setState({menuOpen: false})
    }
  
    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
      this.setState(state => ({menuOpen: !state.menuOpen}))
    }
  
    render () {
      return (
        <div>
          <Menu 
            styles={ styles }
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
          >
            <a className="menu-item" href="#home" onClick={() => this.closeMenu()}>home</a><br></br>
            <a className="menu-item" href="#projects" onClick={() => this.closeMenu()}>projects</a><br></br>
            <a className="menu-item" href="#about" onClick={() => this.closeMenu()}>about</a><br></br>
            <a className="menu-item" href="#getintouch" onClick={() => this.closeMenu()}>join us!</a>
          </Menu>
        </div>
      )
    }
}

export default Hbmenu;


var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '42px',
      height: '42px',
      left: '36px',
      top: '36px'
    },
    bmBurgerBars: {
      background: '#FF3500'
    },
    bmBurgerBarsHover: {
      background: '#A90000'
    },
    bmCrossButton: {
      height: '30px',
      width: '30px'
    },
    bmCross: {
      height: '30px',
      background: '#FF3500'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#3345BC',
      padding: '2.5em 0.5em 0',
      fontSize: '1.65em',
      fontWeight: 'bold'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#000000',
      padding: '0.8em'
    },
    bmItem: {
      color: '#FFFFFF',
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
}
