import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`community born projects`}</h2>
    <ProjectCard title="ETHSafari" link="https://ethsafari.xyz/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Creating an Ethereum event in Africa to entice more people to join the
  Ethereum journey ('safari' in Swahili) we are on.
    </ProjectCard>
    <ProjectCard title="Africoin" link="https://africoin.one/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  An inflation-proof stablecoin for every African.
    </ProjectCard>
    <ProjectCard title="SafariDAO" link="https://safaridao.org/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A DAO a for innovation, collaboration, and knowledge-sharing in Web3.
    </ProjectCard>
    <ProjectCard title="Digital Marker Stones" link="/dms" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Solving severe land conflicts by creating an incorruptible blockchain based
  land registry using Digital Marker Stones.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      